export default function Custom404() {
  return (
    <div className="pointer-events-none fixed left-0 top-0 flex h-full w-full items-center justify-center">
      <div className="pointer-events-auto flex items-center gap-10 text-white/90">
        <div className="text-[24px] font-medium tracking-wider">404</div>
        <div className="h-[60px] w-fit border-l border-white/50" />
        <div className=" text-[14px]">This page could not be found.</div>
      </div>
    </div>
  )
}
